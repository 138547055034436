import { Gradient } from 'dripsy/gradient';
import { useSx, View, Text, ActivityIndicator, useDripsyTheme } from 'dripsy';
import { MotiText, MotiView } from 'moti';
import {
  MotiPressable as DripsyMotiPressable,
  MotiPressableProps,
} from 'moti/interactions';
import React, { ReactNode, useMemo, useState } from 'react';
import { ViewStyle } from 'react-native';

export interface ButtonProps extends MotiPressableProps {
  variantColor?: 'primary' | 'secondary' | 'light';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  variantType?: 'contained' | 'outlined' | 'text' | '3d' | 'menu' | 'bubble-expand';
  iconColor?: string;
  textColor?: string;
  startIconStyle?: ViewStyle;
  textStyle?: any;
  loadingIndicatorSize?: number;
  animatePressable?: ({
    pressed,
    hovered,
  }: {
    pressed: boolean;
    hovered: boolean;
  }) => any;
  contentStyle?: ViewStyle;
  endIconStyle?: ViewStyle;
  onHoveredTextColor?: string,
  onPress?:any
}

export const Button = ({
  style,
  variantColor = 'primary',
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  children,
  variantType = 'contained',
  iconColor,
  textColor,
  textStyle = {},
  startIconStyle,
  loadingIndicatorSize = 25,
  animatePressable,
  contentStyle,
  endIconStyle,
  onHoveredTextColor,
  ...props
}: ButtonProps) => {
  const sx = useSx();
  const [btnHovered, setBtnHovered] = useState<boolean>(false);
  const hoverStyle = useMemo(() => {
    if (animatePressable) {
      return animatePressable({ pressed: true, hovered: true });
    } else if (variantType === 'bubble-expand') {
      return {
        opacity: 1,
      };
    } else {
      return {};
    }
  }, [animatePressable, variantType]);
  const nonHoverStyle = useMemo(() => {
    if (animatePressable) {
      return animatePressable({ pressed: false, hovered: false });
    } else {
      return {};
    }
  }, [animatePressable, variantType]);

  const bgColor = useMemo(() => {
    if (variantColor === 'primary' && (variantType === 'bubble-expand')) {
      return '$primary';
    } else if (variantColor === 'primary') {
      return '$primary';
    } else if (variantColor === 'light') {
      return '$white';
    } else if (variantColor === 'secondary') {
      return '$secondary';
    } else {
      return '$secondary';
    }
  }, [variantColor]);

  const { theme } = useDripsyTheme();
  const variantStyles = useMemo(() => {
    const styleList: any = {
      contained: {
        backgroundColor: bgColor,
        color: '#fff',
      },
      'contained-disabled': {
        backgroundColor: '$disabled',
        color: '#000000',
        borderColor: '$disabled',
        cursor: 'not-allowed',
        borderWidth:1,
      },
      outlined: {
        borderColor: bgColor,
        color: bgColor,
        borderWidth: 1,
        borderStyle: 'solid',
      },
      'outlined-disabled': {
        borderColor: '$disabled',
        color: '$disabled',
        borderWidth: 1,
        borderStyle: 'solid',
        cursor: 'not-allowed',
      },
      text: {
        color: bgColor,
      },
      'text-disabled': {
        color: '#999990',
        cursor: 'not-allowed',
      },
      '3d': {
        color: '$white',
      },
      '3d-disabled': {
        backgroundColor: '$disabled',
        color: '#000000',
        borderColor: '$disabled',
        cursor: 'not-allowed',
      },
      menu: {
        color: bgColor,
      },
      'menu-disabled': {
        color: '#999990',
        cursor: 'not-allowed',
      },
      'bubble-expand': {
        backgroundColor: variantColor === 'light' ? 'rgba(255,255,255,.25)' : '$white',
        color: variantColor === 'light' ? '$white' : '$textColor',
        borderRadius: 20,
        pl: 1,
        height: [35, 40, 42],
        icon: {
          backgroundColor: bgColor,
        },
        text: {
          color: variantColor === 'light' ? '$white' : textColor || '$textColor',
          ml: [0, null, startIcon ? 5 : 3],
          mr: endIcon ? 5 : 3,
          textAlign: 'left',
          justifyContent: 'center',
          alignSelf: 'flex-start',
          fontSize: theme?.text?.btnText,
          fontWeight: '500',
        },
      },
    };

    return disabled || loading
      ? styleList[`${variantType}-disabled`]
      : styleList[variantType];
  }, [variantType, disabled, loading, bgColor]);

  const _renderButton = () => (
    <>
      {startIcon && !loading ? (
        <View
          style={[
            sx({
              marginRight: 1,
            }),
            startIconStyle,
          ]}
        >
          {startIcon}
        </View>
      ) : null}
      <View
        style={[
          sx({
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          }),
          contentStyle,
        ]}
      >
        <>
          {loading && (
            <ActivityIndicator
              style={sx({ marginRight: 2, marginLeft: 2 })}
              size={loadingIndicatorSize}
              color="rgb(28 118 186 / 50%)"
            />
          )}
          {typeof children === 'string' ? (
            <>
              {variantType === 'bubble-expand' ? (
                <MotiText
                  style={[
                    sx(variantStyles.text),
                    textStyle,
                    btnHovered ? { color: iconColor } : {},
                  ]}
                >
                  {children}
                </MotiText>
              ) : (
                <Text
                  style={[
                    sx({
                      zIndex: 2,
                      color: variantStyles.color,
                    }),
                    textColor ? { color: textColor } : {},
                    { fontSize: theme?.text?.btnText },
                    textStyle,
                    btnHovered && onHoveredTextColor
                      ? { color: onHoveredTextColor }
                      : {},
                  ]}
                  numberOfLines={1}
                  ellipsizeMode={'tail'}
                >
                  {children}
                </Text>
              )}
            </>
          ) :
          React.isValidElement(children) ? (
            React.cloneElement(children as React.ReactElement<any>, {
              style: [
                (children as React.ReactElement<any>).props.style,
                sx({
                  zIndex: 2,
                }),
                textColor ? { color: textColor } : {},
                { fontSize: theme?.text?.btnText },
                textStyle,
                btnHovered && onHoveredTextColor
                  ? { color: onHoveredTextColor }
                  : {},
              ],
            })
          ) : (
            children
          )}
        </>
      </View>

      {endIcon && !loading ? (
        <>
          {variantType === 'bubble-expand' ? (
            <MotiView
              animate={{
                width: btnHovered ? '100%' : '0%',
              }}
              transition={{
                type: 'timing',
                duration: 700,
                value: {}
                // easing: Easing.bezier(0.39, 0.25, 0.1, 1.03),
              }}
              style={[
                sx({
                  zIndex: -1,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  minWidth: [35, 40, 42],
                  height: [35, 40, 42],
                  color: 'white',
                  backgroundColor:
                    variantStyles.icon?.backgroundColor || '$primary',
                  borderRadius: 33,
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }),
                {
                  paddingHorizontal: 14,
                }
              ]}
            >
              {endIcon}
            </MotiView>
          ) : (
            <View
              style={[
                sx({
                  marginRight: 1,
                }),
                endIconStyle
              ]}
            >
              {endIcon}
            </View>
          )}
        </>
      ) : null}
    </>
  );

  return (
    <DripsyMotiPressable
      disabled={disabled || loading}
      onHoverIn={() => setBtnHovered(true)}
      onHoverOut={() => setBtnHovered(false)}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';
            return {
              opacity: hovered || pressed ? 0.5 : 1,
              ...(hovered || pressed ? hoverStyle : nonHoverStyle),
            };
          },
        [hoverStyle, nonHoverStyle]
      )}
      accessibilityRole="button"
      style={
        variantType === '3d'
          ? []
          : [
            sx({
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              paddingX: 10,
              paddingY: 2,
              margin: 1,
              ...variantStyles,
            }),
            style,
          ]
      }
      {...props}
    >
      {variantType === '3d' ? (
        <Gradient
          colors={
            disabled || loading ? ['#CCC', '#999'] : ['#1D76BB', '#0e3a5d']
          }
          style={[
            sx({
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              paddingX: 10,
              paddingY: 2,
              margin: 1,
              ...variantStyles,
            }),
            style,
          ]}
        >
          {_renderButton()}
        </Gradient>
      ) : (
        _renderButton()
      )}
    </DripsyMotiPressable>
  );
};
